<svelte:options customElement={{ tag: "bt-analytics-lessons-index", shadow: "none", }} />

<script lang="ts">
  import { onMount } from 'svelte';

  import { dateStore } from '$comp/analytics/dateStore';
  import AnalyticsCard from '$comp/analytics/AnalyticsCard.svelte';
  import Analytics from '$lib/layouts/Analytics.svelte';
  import AnalyticsTable from '$comp/analytics/AnalyticsTable.svelte';
  import Grid from '$comp/analytics/Grid.svelte';
  import PageHeader from '$comp/analytics/PageHeader.svelte';

  const title = "Lesson Analytics";

  export let dataUrl;
  export let startDate: string = "";
  export let endDate: string = "";
  export let hidePathsFlag;

  onMount(() => {
    dateStore.initialize(startDate, endDate);
  });

  $: loading = 0;
</script>

<Analytics {hidePathsFlag}>
  <PageHeader {loading} {title} />

  <Grid>
    <AnalyticsCard className="col-span-full">
      <AnalyticsTable {dataUrl} bind:loading tableName={title} />
    </AnalyticsCard>
  </Grid>
</Analytics>
