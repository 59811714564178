<script lang="ts">
  import { onMount } from 'svelte';

  import { dateStore } from '$comp/analytics/dateStore';
  import DatePicker from '$comp/analytics/DatePicker.svelte';

  const defaultStartDate = dateStore.getDefaultStart();
  const defaultEndDate = dateStore.getDefaultEnd();

  export let hidePathsFlag;

  let currentPage = '';
  let currentHoveredLink: HTMLDivElement;
  let linkItems: any[] = [];
  let indicator: HTMLDivElement;
  let startDate: string;
  let endDate: string;

  let links = [
    { name: 'Dashboard', base: '/analytics', url: '/analytics' },
    { name: 'Curations', base: '/analytics/curations', url: '/analytics/curations' },
    { name: 'Lessons', base: '/analytics/lessons', url: '/analytics/lessons' },
  ];
  if (!hidePathsFlag) {
    links.push(
        { name: 'Learning Paths & Classes', base: '/analytics/paths', url: '/analytics/paths' }
      )
  }
  links.push(
    { name: 'Courses', base: '/analytics/courses', url: '/analytics/courses' },
    { name: 'Learners', base: '/analytics/learners', url: '/analytics/learners' }
  );

  function handleHover() {
    indicator.style.opacity = '1';
    indicator.style.width = `${currentHoveredLink.offsetWidth}px`;
    indicator.style.height = `${currentHoveredLink.offsetHeight}px`;
    indicator.style.left = `${currentHoveredLink.offsetLeft}px`;
  }

  function resetHover() {
    indicator.style.opacity = '0';
  }

  function updateLinks() {
    let startParam = startDate && startDate !== defaultStartDate ? `start_date=${startDate}` : '';
    let endParam = endDate && endDate !== defaultEndDate ? `end_date=${endDate}` : '';
    let queryString = startParam || endParam ? `?${startParam}${endParam ? `&${endParam}` : ''}` : '';

    links = links.map(link => {
      return {
        ...link,
        url: `${link.base}${queryString}`
      };
    });

    // also, update the browser URL with the new dates,
    // without reloading the page
    window.history.pushState(
      { startDate: startDate, endDate: endDate },
      '',
      `${window.location.pathname}${queryString}`
    );
  }

  onMount(() => {
    currentPage = window.location;

    dateStore.subscribe(() => {
      startDate = dateStore.getStart();
      endDate = dateStore.getEnd();

      startDate && endDate && updateLinks();
    });

    window.addEventListener('popstate', (event) => {
      if (event.state && event.state.startDate && event.state.endDate) {
        if (startDate !== event.state.startDate || endDate !== event.state.endDate) {
          dateStore.initialize(event.state.startDate, event.state.endDate);
        }
      }
    });
  });
</script>

<!-- subNav -->
<div on:mouseleave={() => resetHover()} class="w-full border-b border-neutral-500 flex gap-0 text-sm overflow-x-auto relative pt-4 -mt-4">
  {#each links as link, i}
    <a
      on:mouseover={() => {
        currentHoveredLink = linkItems[i];
        handleHover();
      }}
      on:focus={() => {
        currentHoveredLink = linkItems[i];
        handleHover();
      }}
      bind:this={linkItems[i]}
      class="border-b-2 px-4 py-2 border-transparent whitespace-nowrap"
      class:active={link.base == currentPage.pathname}
      href={link.url}
    >
      {link.name}
    </a>
  {/each}
  <div style="transition: all 0.8s cubic-bezier(0.16, 1, 0.3, 1), opacity 0.3s ease;" class="absolute py-1 pointer-events-none" bind:this={indicator}>
    <div class="w-full h-full bg-neutral-600 bg-opacity-10 rounded-md" />
  </div>
  <div class="hidden md:block absolute right-0.5 bottom-[2.25rem] italic text-xs">
    Note: data updates daily at 5:00 AM ET
  </div>
</div>

<div class="mt-4 pb-32">
  <div class="float-right">
    <DatePicker />
  </div>
  <slot />
</div>

<style>
  .active {
    border-color: #000;
  }
</style>
