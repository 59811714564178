<script>
	// Handmade? No other instances found online
	export let iconClass = '';
</script>

<svg
	class={iconClass}
	viewBox="0 0 21 21"
	fill="none"
	xmlns="http://www.w3.org/2000/svg"
>
	<path
		d="M17.5 1.5V13H6.5V8L0 14.5L6.5 21V16H20.5V1.5C20.5 0.672 19.828 0 19 0C18.172 0 17.5 0.672 17.5 1.5Z"
	/>
</svg>
