<svelte:options customElement={{ tag: "bt-mobile-menu-button", shadow: "none" }} />

<script lang="ts">
  import { onMount } from 'svelte';
  import { debounce } from '$shared/lib/debounce';
  import Icon from '$shared/icons/Icon.svelte';

  export let targetID = '';

  let isClosed = true;
  let targetElement: Element | null;

  onMount(() => {
    targetElement = document.getElementById(targetID);
  });

  const handleClick = () => {
    if (targetElement) {
      isClosed = !isClosed;
      targetElement.classList.toggle('hidden', isClosed);
    } else {
      throw new Error('Mobile menu button could not find target element');
    }
  };

  const handleResize = () => {
    if (targetElement && !isClosed) {
      if (window.innerWidth >= 900) {
        isClosed = true;
        targetElement.classList.add('hidden');
      }
    }
  }
</script>

<svelte:window on:resize={debounce(handleResize)} />

<button 
  type="button"
  class="inline-flex items-center justify-center p-2 text-neutral-400 rounded-md hover:text-neutral-100 hover:no-underline focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-neutral-700 focus:ring-neutral-100"
  aria-controls="mobile-menu"
  aria-expanded={`${isClosed ? 'false' : 'true'}`}
  aria-labelledby={`${targetID}-toggle-label`}
  on:click={handleClick}
>
  <span id={`${targetID}-toggle-label`} class="sr-only">
    {isClosed ? 'Open main menu':'Close main menu'}
  </span>
  {#if isClosed}
    <Icon icon="HamburgerMenu" size="medium" iconClass="block"/>
  {:else}
    <Icon icon="XAlt" size="medium"/>
  {/if}
</button>