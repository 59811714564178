<script>
  // Handmade by in-house designer at some point several years ago (as of 11/2024)
  export let iconClass = '';
</script>

<svg xmlns="http://www.w3.org/2000/svg" class={iconClass} stroke-width="1.5" stroke="currentColor" viewBox="0 0 136 149" fill="none">
  <path d="M50.6973 64.5174C51.5072 67.926 54.198 70.0339 57.7322 70.0339C57.7657 70.0339 57.8059 70.0339 57.8393 70.0339C60.8113 70.0006 73.1475 70.0139 76.5345 70.0339H76.5813C80.0151 70.0339 82.6926 67.9594 83.4088 64.7508C84.0581 61.8291 85.6043 59.1809 88.4089 56.1592C95.1493 48.9083 97.6326 39.9964 95.5844 30.3775C92.2711 14.7818 77.7929 5.04945 61.8756 7.75769C48.455 10.0323 38.1669 21.9926 37.9327 35.5739C37.7921 43.8453 40.6636 51.0429 46.4737 56.973C48.629 59.1676 50.0079 61.6424 50.6973 64.5307V64.5174ZM63.7364 18.6507C73.6563 16.9764 82.6725 22.9932 84.7274 32.6588C86.026 38.7824 84.5668 44.0054 80.2628 48.6348C77.0901 52.0501 74.9146 55.3987 73.5759 58.9608C71.5344 58.9608 68.8904 58.9541 66.3134 58.9541C64.2451 58.9541 62.2303 58.9541 60.5971 58.9608C59.2785 55.3454 57.2101 52.0835 54.4189 49.2351C50.704 45.4463 48.9436 41.037 49.0306 35.754C49.1712 27.5425 55.6305 20.0248 63.7431 18.6507H63.7364Z"></path>
  <path d="M80.6039 75.9307H53.6021V86.9904H80.6039V75.9307Z" fill="black"></path>
  <path d="M135.993 33.1392H108.951V44.1989H135.993V33.1392Z" fill="black"></path>
  <path d="M27.042 33.1392H0V44.1989H27.042V33.1392Z" fill="black"></path>
  <path d="M124.11 0.000589801L100.69 13.4751L106.239 23.0531L129.658 9.57863L124.11 0.000589801Z" fill="black"></path>
  <path d="M29.7511 54.2926L6.33203 67.7671L11.881 77.3451L35.3001 63.8706L29.7511 54.2926Z" fill="black"></path>
  <path d="M11.8888 -0.000891285L6.33984 9.57715L29.7589 23.0517L35.3079 13.4736L11.8888 -0.000891285Z" fill="black"></path>
  <path d="M106.239 54.2872L100.69 63.8652L124.11 77.3397L129.658 67.7617L106.239 54.2872Z" fill="black"></path>
  <path d="M43.1065 112.765C43.1065 106.168 37.745 100.825 31.125 100.825C24.5051 100.825 19.1436 106.168 19.1436 112.765C19.1436 119.363 24.5051 124.706 31.125 124.706C37.745 124.706 43.1065 119.363 43.1065 112.765Z" fill="black"></path>
  <path d="M67.9641 124.668C74.5646 124.2 79.5346 118.488 79.065 111.911C78.5953 105.333 72.8638 100.38 66.2633 100.848C59.6628 101.316 54.6927 107.028 55.1624 113.605C55.6321 120.183 61.3636 125.136 67.9641 124.668Z" fill="black"></path>
  <path d="M103.938 124.665C110.539 124.197 115.509 118.485 115.039 111.907C114.569 105.329 108.838 100.376 102.237 100.844C95.6369 101.312 90.6669 107.024 91.1365 113.602C91.6062 120.18 97.3377 125.133 103.938 124.665Z" fill="black"></path>
  <path d="M112.251 124.699H93.8975C89.0313 124.699 85.0821 128.635 85.0821 133.484C85.0821 128.635 81.1396 124.699 76.2667 124.699H57.9129C53.0466 124.699 49.0974 128.635 49.0974 133.484C49.0974 128.635 45.1482 124.699 40.282 124.699H21.9282C17.062 124.699 13.1128 128.635 13.1128 133.484V149H121.067V133.484C121.067 128.635 117.118 124.699 112.251 124.699Z" fill="black"></path>
</svg>