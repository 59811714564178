import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="deletion"
export default class extends Controller {
  static values = {
    favoritors: Number,
    textRemoveItem: String,
    textDelete: String,
    textDeleteFavoritors: String
  }

  delete(event) {
    event.preventDefault();
    const msg = event.params.msg;
    let confirmed = false;

    if (msg == "item") {
      confirmed = confirm(this.textRemoveItemValue);
    } else if (this.favoritorsValue > 0) {
      confirmed = confirm(this.textDeleteFavoritorsValue);
    } else {
      confirmed = confirm(this.textDeleteValue);
    }

    if(!confirmed) {
      event.preventDefault();
    }
  }
}
